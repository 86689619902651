<template>
  <!-- 修改代购订单 -->
  <div class="container">
    <div class="topBtns">
      <el-button class="btnColor btns" @click="confirmSave">保存修改</el-button>
    </div>
    <!-- 基本信息 -->
    <div class="itemBox">
      <h4 class="boxTitle">基本信息</h4>
      <div class="contentBox">
        <div class="inputItem">
          <div class="inputLabel">代购单号:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.dgOrderNumber"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">代购状态:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.orderTypeShow"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">订单类型:</div>
          <el-select
            class="inputs"
            v-model="baseInfo.orderForm"
            clearable
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in ordeFormList"
              :key="item.statusVal"
              :label="item.showName"
              :value="item.statusVal"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputItem">
          <div class="inputLabel">会员号:</div>
          <el-select
            class="inputs"
            v-model="baseInfo.memberId"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="changeMember"
          >
            <el-option
              v-for="item in memberList"
              :label="item.memberId + ' (' + item.nickname + ')'"
              :value="item.memberId"
              :key="item.memberId"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputItem">
          <div class="inputLabel">归属代理商:</div>
          <el-input
            class="inputs"
            placeholder="根据会员自动获取"
            v-model="baseInfo.agentName"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">创建时间:</div>
          <el-input
            class="inputs"
            disabled
            placeholder="请输入内容"
            v-model="baseInfo.createTime"
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">创建人:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.createUserName"
            disabled
          >
          </el-input>
        </div>

        <div class="inputItem">
          <div class="inputLabel">付款方式:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.paymentTypeShow"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">付款时间:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.payTime"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">付款人:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.payUserName"
            disabled
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">采购单号:</div>
          <el-input
            class="inputs"
            placeholder="请输入内容"
            v-model="baseInfo.purchaseOrderNumber"
            disabled
          >
          </el-input>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="itemBox">
      <h4 class="boxTitle">商品信息</h4>
      <div class="contentBox_goods">
        <div class="btnList marginBottom10">
          <!-- <el-button class="btnColor btns">选择添加商品</el-button> -->
          <el-button class="btnColor btns" @click="addGoods(1)"
            >手动添加商品</el-button
          >
        </div>

        <div
          class="goodsInfo"
          v-for="(item, index) in goodsInfoData"
          :key="index"
        >
          <div class="contentBox">
            <div class="inputItem" style="width: 100%">
              <div class="inputLabel">下单备注:</div>
              <el-input
                clearable
                class="inputs"
                placeholder="自动带出 "
                v-model="item.orderRemarks"
                disabled
              >
              </el-input>
            </div>
          </div>
          <!-- 商品表格 -->
          <el-table
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column prop="itemTitle" label="商品标题" min-width="260">
              <template slot-scope="scope">
                <div class="goodsImgTitle">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="goodsInfoData[scope.$index].itemPic"
                    fit="contain"
                  ></el-image>
                  <div class="goodsTitle">
                    {{ goodsInfoData[scope.$index].itemTitle }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="itemUrl" label="商品链接" min-width="200">
              <template slot-scope="scope">
                <div
                  class="linkColor"
                  @click="linkPages(goodsInfoData[scope.$index].itemUrl)"
                >
                  {{ goodsInfoData[scope.$index].itemUrl }}
                  <!-- <a
                    :href="goodsInfoData[scope.$index].itemUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ goodsInfoData[scope.$index].itemUrl }}</a
                  > -->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="itemSpecs" label="规格" width="150">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemSpecs }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="actualPrice" label="单价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].actualPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemPrice" label="原单价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemPrice }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="itemCount" label="数量" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemCount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="domesticFreight" label="国内运费" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].domesticFreight }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="serviceAmount" label="服务费" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].serviceAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="itemAmount" label="总价" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].itemAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="isChangePrices" label="是否改价" width="80">
              <template slot-scope="scope">
                <div
                  style="color: #f00"
                  v-if="goodsInfoData[scope.$index].isChangePrices"
                >
                  是
                </div>
                <div v-if="!goodsInfoData[scope.$index].isChangePrices">否</div>
              </template>
            </el-table-column>
            <el-table-column prop="reasons" label="修改原因" width="80">
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].reasons }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="platformTypeShow"
              label="商品来源"
              width="80"
            >
              <template slot-scope="scope">
                <div>{{ goodsInfoData[scope.$index].platformTypeShow }}</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="addGoods(scope.$index, scope.row)"
                  type="text"
                  size="small"
                  >修改</el-button
                >
                <el-button @click="DelGoods(scope.row)" type="text" size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 收货地址 -->
    <div class="itemBox">
      <h4 class="boxTitle">收货地址</h4>
      <div class="contentBox">
        <div class="inputItem">
          <div class="inputLabel">收件人:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.fullName"
          >
          </el-input>
        </div>

        <div class="inputItem">
          <div class="inputLabel">公司名称:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.companyName"
          >
          </el-input>
        </div>

        <div class="inputItem">
          <div class="inputLabel">联系电话:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.phone"
          >
          </el-input>
        </div>

        <div class="inputItem">
          <div class="inputLabel">国家:</div>
          <el-select
            class="inputs"
            v-model="addressReq.areaCountryId"
            filterable
            clearable
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in areaCountList"
              :key="item.areaCountryId"
              :label="item.showName"
              :value="item.areaCountryId"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputItem">
          <div class="inputLabel">州/省:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.provinceName"
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">城市:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.cityName"
          >
          </el-input>
        </div>
        <div class="inputItem">
          <div class="inputLabel">邮政编码:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.postalCode"
          >
          </el-input>
        </div>
        <div class="inputItem textareaItem">
          <div class="inputLabel">详细地址:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="addressReq.address"
          >
          </el-input>
        </div>
      </div>
    </div>

    <!-- 打包信息 -->
    <div class="itemBox">
      <h4 class="boxTitle">打包信息</h4>
      <div class="contentBox">
        <div class="inputItem">
          <div class="inputLabel">运输方式:</div>
          <el-select
            class="inputs"
            v-model="transportReq.channelTransportId"
            filterable
            clearable
            placeholder="请选择"
            @change="selectTId"
          >
            <el-option
              v-for="(item, index) in transportList"
              :label="item.transportName"
              :key="index"
              :value="item.channelTransportId"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputItem">
          <div class="inputLabel">渠道:</div>
          <el-select
            class="inputs"
            v-model="transportReq.channelId"
            filterable
            clearable
            placeholder="请输入"
          >
            <el-option
              v-for="item in channelList"
              :label="item.channelName"
              :key="item.channelId"
              :value="item.channelId"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputItem">
          <div class="inputLabel">付款方式:</div>
          <el-select
            class="inputs"
            v-model="transportReq.paymentMethodStatus"
            filterable
            clearable
            placeholder="请输入"
          >
            <el-option
              v-for="item in payType"
              :label="item.showName"
              :key="item.statusVal"
              :value="item.statusVal"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputItem textareaItem">
          <div class="inputLabel">备注:</div>
          <el-input
            clearable
            class="inputs"
            placeholder="请输入内容"
            v-model="transportReq.comment"
          >
          </el-input>
        </div>
        <!-- <div class="packServe">
          <div class="inputLabel">包装服务:</div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="复选框 A"></el-checkbox>
            <el-checkbox label="复选框 B"></el-checkbox>
            <el-checkbox label="复选框 C"></el-checkbox>
            <el-checkbox label="禁用" disabled></el-checkbox>
            <el-checkbox label="选中且禁用" disabled></el-checkbox>
          </el-checkbox-group>
        </div> -->
      </div>
    </div>

    <div>
      <el-dialog
        title="编辑商品"
        :visible.sync="dialogVisible"
        width="30%"
        @close="handleClose"
      >
        <div class="addGoodsForm">
          <el-form
            :inline="true"
            :model="addGoodsData"
            label-width="80px"
            class="demo-form-inline"
            :rules="rulesA"
            ref="addForm"
          >
            <el-form-item label="商品标题" prop="itemTitle" style="width: 100%">
              <el-input
                v-model="addGoodsData.itemTitle"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品链接" prop="itemUrl">
              <el-input
                v-model="addGoodsData.itemUrl"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="itemSpecs">
              <el-input
                v-model="addGoodsData.itemSpecs"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="单价" prop="itemPrice">
              <div class="disFlex">
                <el-input
                  type="number"
                  v-model="addGoodsData.itemPrice"
                  disabled
                  placeholder="请输入"
                  @input="inputNumfn($event, 'itemPrice')"
                ></el-input>
                <el-button
                  v-if="!addGoodsData.isChangePrices"
                  type="warning"
                  @click="isChangePriceFN()"
                  >修改价格</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="addGoodsData.isChangePrices"
              label="当前单价"
              prop="actualPrice"
            >
              <div class="disFlex">
                <el-input
                  type="number"
                  v-model="addGoodsData.actualPrice"
                  @input="inputNumfn($event, 'actualPrice')"
                  placeholder="请输入"
                ></el-input>
                <el-button
                  v-if="addGoodsData.isChangePrices"
                  type="warning"
                  @click="isChangePriceFN()"
                  >取消修改</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="addGoodsData.isChangePrices"
              label="修改原因"
              prop="reasons"
            >
              <div class="disFlex">
                <el-input
                  v-model="addGoodsData.reasons"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="数量" prop="itemCount">
              <el-input
                type="number"
                v-model="addGoodsData.itemCount"
                @input="inputNumfn($event, 'itemCount')"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="国内运费" prop="domesticFreight">
              <el-input
                type="number"
                v-model="addGoodsData.domesticFreight"
                @input="inputNumfn($event, 'domesticFreight')"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务费" prop="serviceAmount">
              <el-input
                type="number"
                v-model="addGoodsData.serviceAmount"
                @input="inputNumfn($event, 'serviceAmount')"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="总价">
              <el-input
                type="number"
                readonly
                v-model="addGoodsData.itemAmount"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品来源">
              <el-input
                readonly
                v-model="addGoodsData.platformTypeShow"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="comfirmBtns">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div style="height: 50px; width: 100%"></div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tool from "@/assets/js/tool";
import JsBarcode from "jsbarcode";
export default {
  components: {},
  inject: ["reload"],
  data() {
    return {
      // 基础信息
      baseInfo: {
        memberId: "", //会员号
        orderForm: "pu_tong:order_form", //订单类型
        agentName: "", //归属代理商
        agentId: "", //归属代理商id
        areaCountryId: "",

        createTime: "",
        createUserName: "",
        dgOrderNumber: "",
        orderTypeShow: "",
      },

      //收货地址
      addressReq: {
        address: "", //详细地址
        areaCountryId: "", //目的国家id
        areaName: "", //区
        provinceName: "", //省
        cityName: "", //市
        companyName: "", //公司名称
        fullName: "", //收件人
        phone: "", //联系电话
        postalCode: "", //邮编
        userAddressId: "", //用户地址id
      },

      // 运输信息
      transportReq: {
        channelId: "", //渠道id
        channelTransportId: "", //运输方式id
        comment: "", //备注
        packBillServices: [],
        paymentMethodStatus: "", //付款方式类型{ ji_fu:payment_method_status=寄付 }
      },

      //其他定义
      options: [],
      loading: false,
      checkList: [],
      tableData: [{ name: "xxx" }],

      // 商品信息
      // orderItem: [
      //   {
      //     actualPrice: "", //商品修改单价
      //     domesticFreight: "", //国内运费
      //     itemAmount: "", //商品总付金额
      //     itemCount: "", //商品数量
      //     itemPic: "", //商品主图
      //     itemPrice: "", //商品单价
      //     itemSpecs: "", //商品规格
      //     itemTitle: "", //商品标题
      //     itemUrl: "", //商品链接
      //     orderRemarks: "", //下单备注
      //     reasons: "", //单价修改原因
      //     shopName: "", //店铺名称
      //     skuId: "", //第三方平台的商品id
      //     platformType: "", //第三方平台类型(订单来源平台){\n taobao:platform_type=淘宝\n jing_dong:platform_type=京东\n pin_duo_duo:platform_type=拼多多\n wei_pin_hui:platform_type=唯品会\n }
      //     itemServiceReqs: [
      //       {
      //         itemServiceId: "", //费项id
      //         itemServiceTitle: "", //费项标题
      //         serviceCount: "", //费项数量
      //         unitPrice: "", //费项单价
      //       },
      //     ],
      //   },
      // ],
      // 商品信息
      goodsInfoData: [],
      addGoodsData: {
        itemTitle: "",
        itemUrl: "",
        itemSpecs: "",
        itemPrice: "",
        itemCount: "",
        domesticFreight: "",
        itemAmount: "",
        itemPic: "",
        shopName: "",
        skuId: "",
        actualPrice: "",
        itemServiceReqs: [],
        orderRemarks: "",
        platformType: "taobao:platform_type",
        isChangePrices: false,
        reasons: "",
        platformTypeShow: "淘宝",
      },
      // 添加商品弹窗
      dialogVisible: false,
      diaFormData: {},

      areaCountList: [], //国家地区下拉列表
      channelAndTransportList: [], //  渠道&运输方式下拉数据
      transportList: [], //  运输方式下拉菜单
      channelList: [], //  渠道方式下拉菜单
      payType: [], //  付款方式下拉菜单
      ordeFormList: [], //  订单类型
      memberList: [],
      platformTypeList: [
        {
          value: "taobao:platform_type",
          label: "淘宝",
        },
        {
          value: "jing_dong:platform_type",
          label: "京东",
        },
        {
          value: "pin_duo_duo:platform_type",
          label: "拼多多",
        },
        {
          value: "wei_pin_hui:platform_type",
          label: "唯品会",
        },
      ],
      // isChangePrices: "no",
      // ischangePrice: [
      //   {
      //     value: "yes",
      //     label: "是",
      //   },
      //   {
      //     value: "no",
      //     label: "否",
      //   },
      // ],
      FxList: [], //费项列表
      rulesA: {
        itemTitle: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemUrl: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemSpecs: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemPrice: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        itemCount: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        domesticFreight: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        serviceAmount: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
    };
  },
  created() {
    this.getVipList();
    // this.getFxList();
    this.getAreaContryList();
    this.getChannelAndTransportList();
    this.myGetStatusValList("bill_pack_v1.payment_method_status", 1); //  获取支付方式
    this.myGetStatusValList("dg_order_v1.order_form", 2); //  获取订单类型
    //
  },
  mounted() {},
  activated() {
    //
    let querys = this.$route.query;
    console.log(querys);
    if (querys.pageType) {
      this.pageType = querys.pageType;
    }

    if (querys.pageType && querys.pageType == "edit") {
      //修改进来,
      // 获取采购单详情
      Api.bmg_getDgOrderDetail({
        dgOrderId: querys.dgOrderId,
      }).then((res) => {
        let datas = res.data.result || {};
        console.log(datas);
        //获取当前待购单id
        // this.getIndentList(datas.baseInfoVo.dgOrderNumber);
        this.baseInfo = datas.baseInfo || {}; //基本信息
        this.addressReq = datas.addressVo || {}; //地址信息
        this.goodsInfoData = datas.orderItem || []; //商品信息
        this.transportReq = datas.transportVo || {}; //运输信息

        this.goodsInfoData.forEach((item) => {
          item.itemServiceReqs = item.chaiXiangServiceVo || [];
        });
        if (
          this.goodsInfoData[0].actualPrice !== this.goodsInfoData[0].itemPrice
        ) {
          this.goodsInfoData[0].isChangePrices = true;
        }
      });
    }
  },
  deactivated() {},
  watch: {
    addGoodsData: {
      handler(a, b) {
        let price = 0;

        if (a.isChangePrices) {
          price = a.actualPrice || 0;
        } else {
          price = a.itemPrice || 0;
        }

        this.addGoodsData.itemAmount = this.Tools.mathToFixed(
          price * a.itemCount +
            (a.domesticFreight || 0) * 1 +
            (a.serviceAmount || 0) * 1
        );
      },
      deep: true,
    },
  },
  methods: {
    linkPages(url) {
      window.open(url);
    },
    // 是否修改价格
    isChangePriceFN() {
      this.addGoodsData.isChangePrices = !this.addGoodsData.isChangePrices;
      console.log(this.addGoodsData.isChangePrices);
      if (this.addGoodsData.isChangePrices) {
      } else {
        this.addGoodsData.actualPrice = this.addGoodsData.itemPrice;
      }
    },
    //只能输入正数和小数
    inputNumfn(e, type) {
      let num = Number(e.replace(/[^\d.]/g, ""));

      if (type == "actualPrice") {
        this.addGoodsData.actualPrice = num;
      }
      if (type == "itemPrice") {
        this.addGoodsData.itemPrice = num;
      }
      if (type == "itemCount") {
        num = Number(e.replace(/\D+/, "")) || "";
        this.addGoodsData.itemCount = num;
      }
      if (type == "domesticFreight") {
        this.addGoodsData.domesticFreight = num;
      }
      if (type == "serviceAmount") {
        this.addGoodsData.serviceAmount = num;
      }
    },
    changeServeList(e) {
      let ids = e || [];
      for (var a = 0; a < this.FxList.length; a++) {
        for (var b = 0; b < ids.length; b++) {}
      }
    },
    // 获取费项
    getFxList() {
      Api.getItemServiceList_alls({
        serviceStatus: "yan_huo:service_status",
      }).then((res) => {
        this.FxList = res.data.result || [];
        this.FxList.forEach((item) => {
          item.serviceCount = 1;
        });
      });
    },
    getChannelAndTransportList() {
      //  获取渠道与运输方式下拉菜单
      Api.channelAndTransportList().then((res) => {
        if (res.data.status === "success") {
          let obj = {};
          this.transportList = res.data.result.reduce((cur, next) => {
            //  去重
            obj[next.channelTransportId]
              ? ""
              : (obj[next.channelTransportId] = true && cur.push(next));
            return cur;
          }, []);
          this.channelAndTransportList = res.data.result || [];
        } else {
          this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`);
        }
      });
    },

    selectTId(val) {
      //  选择运输方式下拉框时筛选渠道数据
      let arr = [];
      this.channelAndTransportList.forEach((item) => {
        if (item.channelTransportId === val) {
          arr.push(item);
        }
      });
      this.channelList = arr;
    },
    // 获取国家地区列表
    getAreaContryList() {
      Api.getAreaContryList_q().then((res) => {
        let areaCountList = res.data.result || [];
        var showArr = [];
        for (var i = 0; i < areaCountList.length; i++) {
          showArr.push({
            areaCountryId: areaCountList[i].areaCountryId,
            numberCode: areaCountList[i].numberCode,
            showName: areaCountList[i].chineseName,
          });
        }
        this.areaCountList = showArr;
      });
    },

    myGetStatusValList(status, type) {
      Api.getStatusValList({
        tableAndFieldName: status,
      }).then((res) => {
        if (type == 1) {
          //  获取付款方式
          this.payType = res.data.result || [];
        }
        if (type == 2) {
          //  获取订单类型
          this.ordeFormList = res.data.result || [];
        }
      });
    },

    // 手动添加商品
    addGoods(e) {
      if (e == 1 && this.goodsInfoData.length > 0) {
        this.$message.warning("目前只能添加一条数据");
        return false;
      } else if (e == 0) {
        // 修改
        let FormData = JSON.parse(JSON.stringify(this.goodsInfoData[0]));
        this.addGoodsData.actualPrice = FormData.actualPrice || "";
        this.addGoodsData.domesticFreight = FormData.domesticFreight || 0;
        this.addGoodsData.serviceAmount = FormData.serviceAmount || 0;
        this.addGoodsData.itemAmount = FormData.itemAmount || "";
        this.addGoodsData.itemCount = FormData.itemCount || "";
        this.addGoodsData.itemPic = FormData.itemPic || "";
        this.addGoodsData.itemPrice = FormData.itemPrice || "";
        this.addGoodsData.itemSpecs = FormData.itemSpecs || "";
        this.addGoodsData.itemTitle = FormData.itemTitle || "";
        this.addGoodsData.itemUrl = FormData.itemUrl || "";
        this.addGoodsData.orderRemarks = FormData.orderRemarks || "";
        this.addGoodsData.platformType = FormData.platformType || "";
        this.addGoodsData.reasons = FormData.reasons || "";
        this.addGoodsData.shopName = FormData.shopName || "";
        this.addGoodsData.skuId = FormData.skuId || "";
        this.addGoodsData.platformTypeShow = FormData.platformTypeShow || "";
        this.addGoodsData.isChangePrices = FormData.isChangePrices || false;

        // this.addGoodsData = FormData;

        this.dialogVisible = true;
      }
    },
    handleClose() {
      this.addGoodsData = {
        itemTitle: "",
        itemUrl: "",
        itemSpecs: "",
        itemPrice: "",
        itemCount: "",
        domesticFreight: 0,
        serviceAmount: 0,
        itemAmount: "",
        itemPic: "",
        shopName: "",
        skuId: "",
        actualPrice: "",
        itemServiceReqs: [],
        orderRemarks: "",
        platformType: "taobao:platform_type",
        isChangePrices: false,
        reasons: "",
        platformTypeShow: "淘宝",
      };
    },

    changeMember(e) {
      console.log(e);
      this.memberList.forEach((item) => {
        if (item.memberId == e) {
          this.baseInfo.agentName = item.agentName;
        }
      });
    },
    //   动态搜索会员号
    remoteMethod(e) {
      if (e.length > 0) {
        this.getVipList(e);
      }
    },
    getVipList(e) {
      Api.bmg_getMemberDownList({
        memberId: e || "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
        }
      });
    },
    DelGoods(e) {
      this.$confirm("是否删除该商品?", "提示")
        .then((res) => {
          this.goodsInfoData = [];
        })
        .catch(() => {});
    },
    // 确认添加
    comfirmBtns() {
      if (!this.addGoodsData.itemTitle) {
        this.$message.warning("商品标题不能为空");
        return false;
      }
      if (!this.addGoodsData.itemSpecs) {
        this.$message.warning("商品规格不能为空");
        return false;
      }
      if (!this.addGoodsData.itemPrice) {
        this.$message.warning("商品单价不能为空");
        return false;
      }
      if (!this.addGoodsData.itemCount) {
        this.$message.warning("商品数量不能为空");
        return false;
      }
      if (!this.addGoodsData.itemAmount) {
        this.$message.warning("商品总价不能为空");
        return false;
      }
      // if (!this.addGoodsData.itemUrl) {
      //   this.$message.warning("商品链接不能为空");
      //   return false;
      // }
      this.goodsInfoData = [];
      this.goodsInfoData.push(this.addGoodsData);

      this.dialogVisible = false;
    },

    //保存修改
    confirmSave() {
      if (this.goodsInfoData.length == 0) {
        this.$message.warning("请添加一个商品");
        return false;
      }
      if (this.goodsInfoData[0].actualPrice == "") {
        this.goodsInfoData[0].actualPrice =
          this.goodsInfoData[0].itemPrice || 0;
      }

      let param = {
        addressReq: this.addressReq, //收货地址
        orderItem: this.goodsInfoData, //商品信息
        transportReq: this.transportReq, //运输信息
        areaCountryId: this.addressReq.areaCountryId, //目的国家id
        memberId: this.baseInfo.memberId, //会员号
        // orderForm: this.baseInfo.orderForm, //订单类型
        dgOrderId: this.baseInfo.dgOrderId,
      };
      param.sign = tool.getSign(param);
      console.log(param);
      Api.bmg_updateDgOrder(param)
        .then((res) => {
          // 返回列表
          this.Tools.closePage();
          this.reload();
          this.$router.push("/pages/IndentOrder/IndentOrderList");
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
$borColor: #797979;
// .topBtns {
//     position: fixed;
//     top: 80px;
//     left: 220px;
// }
.btns {
  background-color: #f0a261;
  color: #fff;
  border: none;
  padding: 10px;
}
.goodsInfo {
  border: 1px solid #d7d7d7;
  padding: 10px;
}

.itemBox {
  margin-top: 20px;
  width: 100%;

  .boxTitle {
    margin: 0;
    margin-bottom: 4px;
  }

  .contentBox {
    padding: 10px;
    // border: 1px solid $borColor;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .inputItem {
      //   width: 30%;
      width: 270px;
      min-width: 200px;
      margin-right: 20px;
      margin-bottom: 10px;
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      border: 1px solid $borColor;
      border-radius: 4px;

      .inputLabel {
        width: 74px;
        padding-left: 6px;
        font-size: 14px;
      }
      .inputs {
        flex: 1;
        border: none;

        ::v-deep {
          .el-input__inner {
            border: none;
            height: 28px;
            line-height: 28px;
            min-height: 28px;
          }
        }
      }
    }

    .textareaItem {
      width: 562px;
    }
  }
  .contentBox_goods {
    padding: 10px;
    padding-bottom: 0;

    .goodsImgTitle {
      display: flex;
      align-items: center;
      padding: 4px 0;

      .goodsTitle {
        flex: 1;
      }
    }
    ::v-deep {
      .el-table .cell {
        white-space: normal;
      }
    }
  }
}

.packServe {
  //   flex: 1;
  width: 100%;
  display: flex;
  margin-right: 20px;
  margin-bottom: 10px;

  .inputLabel {
    width: 74px;
    min-width: 74px;
    padding-left: 6px;
    font-size: 14px;
  }

  .serveImte {
    width: 200px;
    .countInput {
      width: 100px;
    }
  }
}

.marginBottom10 {
  margin-bottom: 10px;
}

.addGoodsForm {
  /deep/ .el-form--inline .el-form-item {
    display: flex;
  }

  /deep/ .el-form--inline .el-form-item__content {
    flex: 1;
  }
}

.disFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
